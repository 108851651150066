
export default {
  name: 'IndexPage',
  data() {
    return {
      mobileSwitch: false
    }
  },
  mounted() {
    this.setBtagInCookie();

    if (this.$route.query.scrollToRanking) {
      setTimeout(() => {
        this.bindScrollToRanking();
      }, 0);

      let query = Object.assign({}, this.$route.query);
      delete query.scrollToRanking;
      this.$router.replace({query});
    }
  },
  methods: {
    setBtagInCookie() {
      if (this.$nuxt.$route.query && this.$nuxt.$route.query.btag) {
        if (this.$nuxt.$cookies.get('btag') === undefined || this.$nuxt.$cookies.get('btag') === '') {
          const btag = this.$nuxt.$route.query.btag;
          this.$nuxt.$cookies.set('btag', btag, {
            domain: '.quizandmoney.com',
            maxAge: 60 * 60 * 24,
            path: '/',
            samesite: 'none',
            secure: true
          });
        }
      }
    },
    getBtagToRedirect() {
      if (this.$nuxt.$cookies.get('btag') !== undefined && this.$nuxt.$cookies.get('btag') !== '') {
        return this.$nuxt.$cookies.get('btag');
      }
      if (this.$nuxt.$route.query && this.$nuxt.$route.query.btag) {
        return this.$nuxt.$route.query.btag;
      }
      return null;
    },
    bindScrollToRanking() {
      this.$refs.ranking.scrollIntoView({behavior: 'smooth'});
    },
    goToRegister() {
      const btag = this.getBtagToRedirect();
      const url = btag ? `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/register?btag=${btag}` : `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/register`;
      this.pushGtmEvent(url);
      window.location.href = url;
    },
    goToLogin() {
      const btag = this.getBtagToRedirect();
      const url = btag ? `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/login?btag=${btag}` : `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/login`;
      this.pushGtmEvent(url);
      window.location.href = url;
    },
    playNow() {
      const btag = this.getBtagToRedirect();
      const url = btag ? `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/login?btag=${btag}` : `https://panel.quizandmoney.com/${this.$i18n.locale}/auth/login`;
      ;
      this.pushGtmEvent(url);
      window.location.href = url;
    },
    howToPlay() {
      const btag = this.getBtagToRedirect();
      let url;
      if (this.$i18n.locale === 'pl') {
        url = btag ? `https://panel.quizandmoney.com/pl/page/jak-grac?btag=${btag}` : `https://panel.quizandmoney.com/pl/page/jak-grac`;
      }

      if (this.$i18n.locale === 'en') {
        url = btag ? `https://panel.quizandmoney.com/en/page/how-to-play-2?btag=${btag}` : `https://panel.quizandmoney.com/en/page/how-to-play-2`;
      }

      window.location.href = url;
    },
    openMobileSwitch() {
      this.mobileSwitch = !this.mobileSwitch;
    },

    pushGtmEvent(url) {
      this.$gtm.push({
        event: 'conversion',
        'send_to': 'AW-11036211925/E_tkCKXQmYQYENX1vI4p',
        'event_callback': function () {
          window.location = url;
        }
      });
    }
  }
}
