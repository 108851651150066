import { render, staticRenderFns } from "./index.vue?vue&type=template&id=094acbee&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LangSwitcher: require('/builds/quiznmoney/front-lp/components/LangSwitcher.vue').default,StepSwiper: require('/builds/quiznmoney/front-lp/components/StepSwiper.vue').default,DailyRanking: require('/builds/quiznmoney/front-lp/components/DailyRanking.vue').default,WeeklyRanking: require('/builds/quiznmoney/front-lp/components/WeeklyRanking.vue').default,MonthlyRanking: require('/builds/quiznmoney/front-lp/components/MonthlyRanking.vue').default,Footer: require('/builds/quiznmoney/front-lp/components/Footer.vue').default,Footer: require('/builds/quiznmoney/front-lp/components/Footer.vue').default})
