
export default {
  name: 'Footer',
  data() {
    return {
      links: [],
      btagPart: '',
      baseUrl: 'https://panel.quizandmoney.com/'
    }
  },
  mounted() {
    this.getFooter(this.$i18n.locale.toLowerCase());
    if(this.$nuxt.$route.query.btag) {
      this.btagPart='?btag='+this.$nuxt.$route.query.btag;
    }
  },
  computed: {},
  methods: {
    getFooter(lang) {
      this.$axios.get(process.env.apiUrl + `/v1/resource?keyName=footer-menu&locale=${lang}&additionalResourceFields[]=publishedAt&additionalResourceFields[]=status&withChildren=true`)
          .then(response => {
            this.links = response.data.children;
          })
          .catch(() => {

          });
    }
  }
}
