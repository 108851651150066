
export default {
  name: 'RankingTimer',
  props: {
    time: Object,
    title: String
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {},
  methods: {
  }
}
