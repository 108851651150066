
export default {
  name: 'LangSwitcher',
  props: {
    lang: String,
  },
  data() {
    return {
      mobileSwitch: false
    }
  },
  computed: {

  },
  methods: {
    openMobileSwitch() {
      this.mobileSwitch = !this.mobileSwitch;
    }
  }
}
