

import * as moment from "moment";

export default {
  name: 'MonthlyRanking',
  props: {
    lang: String,
    label: String,
  },
  data() {
    return {
      isLoading: true,
      ranking: null,
      rankingDateTo: null,
      timeToEndRanking: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  mounted() {
    this.getRanking();
    this.checkTimeDifferences();
  },
  methods: {
    getRanking() {
      this.$axios.get(process.env.apiUrl + '/v1/ranking/ranking-table/MONTHLY/FUN_POINTS/5')
        .then(response => {
          this.ranking = response.data;
          this.rankingDateTo = response.data.dateTo;
          this.isLoading = false;
        });
    },
    checkTimeDifferences() {
      setInterval(() => {
        this.setRankingTimes();
      }, 1000);
    },
    checkDifferenceBetweenDates() {
      return moment.duration(moment(this.rankingDateTo).diff(moment()));
    },
    setRankingTimes() {
      const difference = this.checkDifferenceBetweenDates();

      if (difference.get('days') > 0) {
        this.timeToEndRanking = {
          days: difference.get('days')
        };
      } else {
        this.timeToEndRanking = {
          days: 0,
          hours: difference.get('hours'),
          minutes: difference.get('minutes'),
          seconds: difference.get('seconds')
        };
      }

      if (difference.get('months') === 0 && difference.get('hours') === 0 && difference.get('minutes') === 0 && difference.get('seconds') === 0) {
        setTimeout(() => {
          this.getRanking();
        }, 5000);
      }
    }
  }
}
