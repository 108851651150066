
  export default {
    name: 'StepSwiper',
    data() {
      return {
        swiperOptions: {
          slidesPerView: 1,
          spaceBetween: 30,
          allowTouchMove: true,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          navigation: {
            nextEl: '.landing-step__arrow--next',
            prevEl: '.landing-step__arrow--prev',
            disabledClass: 'disabled'
          },
          breakpoints: {
            576: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 5,
              allowTouchMove: false,
              spaceBetween: 0
            }
          }
        }
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    }
  }
